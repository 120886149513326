<template>
  <v-main>
    <system-error />

    <v-container fluid>
      <v-snackbar
        v-model="toast2Display.display"
        :timeout="toast2Display.timeout"
        :color="toast2Display.variant"
        v-bind="{
          [toast2Display.parsedDirection[0]]: true,
          [toast2Display.parsedDirection[1]]: true
        }"
        :multi-line="true"
        class="non-printable"
      >
        <div>
          <div class="pb-2">
            <h3>{{ toast2Display.title }}</h3>
          </div>
          <v-divider />
          <div
            v-for="(thisMessage, index) in toast2Display.messages"
            :key="index"
            class="pt-1"
          >
            {{ thisMessage }}
          </div>
        </div>

        <template v-slot:action="{ }">
          <v-icon
            aria-label="Close"
            @click="toast2Display.display = false"
          >
            mdi-close
          </v-icon>
        </template>
      </v-snackbar>

      <router-view :key="$route.path" />
    </v-container>
  </v-main>
</template>

<script>
  import SystemError from '@/layouts/default/SystemError'
  import { get } from 'vuex-pathify'
  import { Auth, Hub } from 'aws-amplify'
  import { DataStore } from '@aws-amplify/datastore'
  import { ApplicationSupport, SystemMessages, UserSettings } from '@/models'
  import moment from 'moment'
  import { mapGetters } from 'vuex'

  export default {
    name: 'DefaultView',

    components: {
      SystemError,
    },

    data: () => ({
      initializedToast: {
        display: false,
        title: '',
        messages: [],
        text: '',
        variant: 'success',
        parsedDirection: ['top', 'center'],
        timeout: 4000,
      },
      toast2Display: {
        display: false,
        messages: [],
        variant: 'success',
        parsedDirection: ['top', 'center'],
        timeout: 4000,
      },
      hubEventListener: undefined,
      subUserSettings: undefined,
      subSystemMessages: undefined,
      subApplicationSupport: undefined,
    }),

    computed: {
      ...mapGetters('user', [
        'getUserEmail',
      ]),
      users: get('user/users'),
      notifications: get('notification/notifications'),
      plans: get('plan/plans'),
      divisions: get('division/divisions'),
      categories: get('category/categories'),
    },

    created () {
      this.hubEventListener = Hub.listen('datastore', async hubData => {
        const { event, data } = hubData.payload
        // console.log('main.js listener', `authState = ${this.users.authState}`, hubData.payload)
        if (this.users.authState === 'signedin') {
          switch (event) {
            case 'ready':
              // do something here once the data is synced from the cloud
              /*
              this.$store.dispatch('user/getUserSettings', null)
               */
              // TODO: Something is wrong if ready never happens. How do we capture this and notify user?
              break
            case 'modelSynced':
              if (data) {
                if (data.model) {
                  if ({}.hasOwnProperty.call(data.model, 'name')) {
                    switch (data.model.name) {
                      case 'UserSettings':
                        this.$store.dispatch('user/getUserSettings', null)
                        break
                      case 'SystemMessages':
                        this.$store.dispatch('systemMessage/retrieveAll')
                        break
                      case 'ApplicationSupport':
                        this.$store.dispatch('billing/retrieve')
                        break
                    }
                  }
                }
              }
              break
            default:
              break
          }
        }
      })

      this.subUserSettings = DataStore.observe(UserSettings).subscribe(obj => {
        const userEmail = this.getUserEmail()
        let changedEmail = null

        if (this.hasObjectAttribute(obj, 'opType')) {
          switch (obj.opType) {
            case 'UPDATE':
              if ({}.hasOwnProperty.call(obj, 'element')) {
                if (obj.element) {
                  if ({}.hasOwnProperty.call(obj.element, 'user_email')) {
                    changedEmail = obj.element.user_email

                    if (userEmail && changedEmail) {
                      if (userEmail === changedEmail.toString().trim()) {
                        if ({}.hasOwnProperty.call(obj.element, 'default_customer')) {
                          this.verifyDefaultCustomer(obj.element.default_customer)
                        }
                      }
                    }
                  }
                }
              }

              break
            default:
              break
          }
        }
      })

      // TODO: We are losing connection to DataStore after DEFAULT_KEEP_ALIVE time has passed.  We need DataStore.clear and the .observe again, or .stop and .start?
      // https://docs.amplify.aws/lib/datastore/datastore-events/q/platform/js/#usage
      // https://docs.amplify.aws/lib/datastore/real-time/q/platform/js/
      this.subSystemMessages = DataStore.observe(SystemMessages).subscribe(obj => {
        this.$store.dispatch('systemMessage/observe', obj)
      })

      this.subApplicationSupport = DataStore.observe(ApplicationSupport).subscribe(obj => {
        this.$store.dispatch('billing/retrieve')
      })

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'user/updateTheme':
            this.initializeTheme()
            break
          case 'user/setUserSettings':
            this.initializeTheme()
            this.verifyDefaultCustomer(this.users.default.client_id)
            /*
            if (mutation.payload === null) {
              // location.reload()
              // this.$router.go(this.$router.currentRoute)
              this.$router.go(0)
            }
             */
            this.$store.dispatch('customer/retrieveAll')
            break
          case 'user/showToast':
            this.displayToast(mutation.payload)
            break
          case 'division/push2All':
            this.$store.dispatch('division/retrieve', this.plans.selectedItem)
            break
          case 'assembly/push2All':
            this.$store.dispatch('assembly/retrieve', this.divisions.selectedItem)
            break
          case 'part/push2All':
            this.$store.dispatch('part/retrieve', this.categories.selectedItem)
            break
          default:
            break
        }
      })
    },

    beforeDestroy () {
      // Remove listener
      this.hubEventListener()
      this.unsubscribe()
    },

    destroyed () {
      if (this.subUserSettings) this.subUserSettings.unsubscribe()
      if (this.subSystemMessages) this.subSystemMessages.unsubscribe()
      if (this.subApplicationSupport) this.subApplicationSupport.unsubscribe()
    },

    methods: {
      async signOut () {
        try {
          await Auth.signOut()
          await this.$store.dispatch('user/setAuth', { authState: 'signedout', authData: undefined })
        } catch (error) {
          console.error('error signing out: ', error)
        }
      },

      verifyDefaultCustomer (defaultCustomer) {
        if (defaultCustomer) {
          if (defaultCustomer.toString().trim() === '0000') {
            this.signOut()
          }
        }
      },

      initializeTheme () {
        if (this.users.settings) {
          this.$vuetify.theme.dark = this.users.settings.dark
        }

        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = this.users.selectedColors.primary
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].secondary = this.users.selectedColors.secondary
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].accent = this.users.selectedColors.accent
      },

      displayToast (payload) {
        this.toast2Display = { ...this.initializedToast }

        this.toast2Display.title = payload.title
        this.toast2Display.messages = payload.messages

        if (payload.variant) {
          this.toast2Display.variant = payload.variant
        }

        if (payload.target) {
          this.toast2Display.parsedDirection = payload.target.split(' ')
        }

        if (payload.timeout) {
          this.toast2Display.timeout = payload.timeout
        }

        this.toast2Display.display = true
      },
    },
  }
</script>

<style>
.navToLink {
  display: inline;
}
.navToLink:hover {
  color: blue;
  cursor: pointer;
  font-weight: bold;
}
.required-icon {
  vertical-align: top;
  color: #dd0000;
}
</style>
