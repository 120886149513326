<template>
  <v-alert
    v-model="showAlert"
    dismissible
    type="error"
    class="text-center ml-10 mr-10 non-printable"
  >
    <div id="system-error">
      <h3
        v-for="(thisMessage, indexOpt) in errors.message"
        :key="indexOpt"
      >
        {{ thisMessage.line }}
      </h3>
      <div>{{ getErrorDetails() }}</div>
    </div>
  </v-alert>
</template>

<script>
  import { get } from 'vuex-pathify'

  export default {
    name: 'SystemError',

    data () {
      return {
        showAlert: false,
      }
    },

    computed: {
      errors: get('error/errors'),
    },

    created () {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'error/setError':
            // TODO: Not sure why this console.error is needed to make errors show up
            console.error('Error Messages', this.errors.message.length)
            this.setShowAlert(this.errors.message.length > 0)
            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      setShowAlert (val) {
        this.showAlert = val

        if (this.showAlert) {
          window.location.hash = '#system-error'
        }
      },

      getErrorDetails () {
        let errorDetails = this.errors.component.name + ': '
        if (!errorDetails.includes(this.errors.details.message)) {
          if (this.errors.details.message) {
            errorDetails += this.errors.details.message
          } else {
            errorDetails += this.errors.details
          }
        }
        return errorDetails
      },
    },
  }
</script>
